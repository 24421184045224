<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="getLocaleText(parameter, 'name')"
                    :isFilter="false"
                    v-if="parameter">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="getLocaleText(parameter, 'name')"
                    :isFilter="false"
                    v-if="parameter">
                </HeaderMobile>
            </template>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable">
            </datatable>

            <CommonModal ref="showModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()"
                v-if="checkPermission('yoksisservice_yoksisparameteritem')">
                <template v-slot:CommonModalTitle>
                    {{ $t('show').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :data="showData" v-if="formProcess == 'show'" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
//Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
//Component
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
//Other
import qs from "qs";
//Service
import YoksisParameterService from "@/services/YoksisParameterService";
//Page
import ShowForm from "./ParameterItem/ShowForm"


export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        Datatable,
        CommonModal,
        ShowForm
    },
    metaInfo() {
        return {
            title: this.$t("parameter"),
        };
    },
    data() {
        return {
            parameter: null,
            parameter_id: 0,
            formId: 0,
            showData: null,
            form: {},
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'yoksisservice_yoksisparameteritem',
                                callback: (row) => {
                                    this.showForm(row);
                                }
                            },
                        ],
                    },
                    {
                        label: this.$t("code"),
                        field: "kod",
                        sortable: true,
                    },
                    {
                        label: this.$t("name"),
                        field: "ad",
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: -1
                },
            },
        };
    },
    methods: {
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            return YoksisParameterService.get(this.parameter_code)
                .then((response) => {
                    this.parameter = response.data.data;
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        showForm(data) {
            this.formId = data.id
            this.showData = data
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        },
    },
    created() {
        this.parameter_code = this.$route.params.id;
        if (this.parameter_code) {
            this.getRows()
        }
        else {
            this.$router.push('/404');
        }
    },
};
</script>

